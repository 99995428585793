<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('sending_sms_to_staff')" @filter-div-status="datatable.filterStatus = $event">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('sending_sms_to_staff')" @filter-div-status="datatable.filterStatus = $event">
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col sm="6" md="4" lg="4" xl="4">
                        <b-form-group :label="$t('name')">
                            <b-form-input size="sm" v-model="datatable.queryParams.filter.name"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('surname')">
                            <b-form-input size="sm" v-model="datatable.queryParams.filter.surname"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('national_number')">
                            <b-form-input type="number" size="sm"
                                          v-model="datatable.queryParams.filter.national_id"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('email')">
                            <b-form-input type="email" size="sm"
                                          v-model="datatable.queryParams.filter.email"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="4">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code"></faculty-selectbox>
                        </b-form-group>
                        <b-form-group :label="$t('program')">
                            <program-selectbox v-model="datatable.queryParams.filter.program_code"
                                               :faculty_code="datatable.queryParams.filter.faculty_code"></program-selectbox>
                        </b-form-group>
                        <b-form-group :label="$t('department')">
                            <department-selectbox v-model="datatable.queryParams.filter.department_code"
                                                  :faculty_code="datatable.queryParams.filter.faculty_code"/>
                        </b-form-group>

                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="4">
                        <b-form-group :label="$t('contract_type')">
                            <parameter-selectbox code="contract_types"
                                                 v-model="datatable.queryParams.filter.contract_type"/>
                        </b-form-group>
                        <b-form-group :label="$t('roles')">
                            <ValidationProvider name="roles" rules="">
                                <role-selectbox :multiple="true" v-model="datatable.queryParams.filter.roles"/>
                            </ValidationProvider>
                        </b-form-group>
                        <b-form-group :label="$t('working_status')">
                            <parameter-selectbox code="working_statuses"
                                                 v-model="datatable.queryParams.filter.working_status"/>
                        </b-form-group>
                    </b-col>

                </b-row>
            </datatable-filter>
            <datatable :select-options="!!datatable.rows.length" @on-selected-rows-change="selectedRows"
                       :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                       :total="datatable.total" :queryParams="datatable.queryParams" :lineNumbers="false"
                       @on-page-change="onPageChange" @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable">
            </datatable>


            <b-button class="mt-4" @click="openModal" :disabled="showButton">
                {{ $t('continue') }}
            </b-button>

            <CommonModal ref="showModal" :onHideOnlyX="true">
                <template v-slot:CommonModalTitle>
                    {{ $t('send_sms').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <b-col cols="12" md="12" lg="12" xl="12">
                        <b-form-group :label="$t('orjinator')">
                            <parameter-selectbox v-model="form.name">
                            </parameter-selectbox>
                        </b-form-group>
                        <b-form-group :label="$t('explanation')">
                            <b-form-input v-model="form.name"></b-form-input>
                        </b-form-group>
                        <b-input-group prepend="TR" class="mb-2">
                            <b-form-textarea
                                id="textarea-rows"
                                v-model="form.objectives_tr"
                                rows="10"
                            />
                        </b-input-group>
                        <b-input-group prepend="EN">
                            <b-form-textarea
                                id="textarea-rows"
                                v-model="form.objectives_en"
                                rows="10"
                            />
                        </b-input-group>

                        <b-form-group :label="$t('excel_file')" class="mt-3">
                            <div class="d-flex custom-file-upload">
                                <b-form-file
                                    v-model="form.file"
                                    :placeholder="$t('select_file')"
                                    :drop-placeholder="$t('drop_file')"
                                    ref="fileInput"
                                    multiple></b-form-file>
                                <b-button variant="outline-secondary"
                                          @click="$refs.fileInput.$el.childNodes[0].click();">{{ $t('browse') }}
                                </b-button>
                            </div>
                            <div class="alert alert-info mt-2">{{ $t('send_sms_excel') }}</div>
                        </b-form-group>


                        <b-button variant="primary" class="mt-4">
                            {{ $t('send_sms') }}
                        </b-button>

                    </b-col>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout"
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

// Components
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        FacultySelectbox,
        ProgramSelectbox,
        ParameterSelectbox,
    },
    metaInfo() {
        return {
            title: this.$t('sending_sms_to_staff')
        }
    },
    data() {
        return {
            showButton: true,
            fakeData: [
                {
                    'email': 'deneme@deneme.com',
                    'name': 'deneme',
                    'faculty_name': 'denem1',
                    'program_name': 'program',
                    'working_status': 'ok',
                    'contract_type': 'ok'
                }
            ],
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: this.$t('name'),
                        field: 'name',
                        sortable: false,
                    },
                    {
                        label: this.$t('email'),
                        field: 'email',
                        sortable: false,
                    },
                    {
                        label: this.$t('faculty'),
                        field: 'faculty_name',
                        sortable: false,
                    },
                    {
                        label: this.$t('program'),
                        field: 'program_name',
                        sortable: false,
                    },
                    {
                        label: this.$t('working_status'),
                        field: 'working_status',
                        sortable: false,
                    },
                    {
                        label: this.$t('contract_type'),
                        field: 'contract_type',
                        sortable: false,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {
                        student_number: null,
                        national_id: null,
                        name: null,
                        surname: null,
                        payment_status: null,
                        registration_status: null,
                        faculty_code: null,
                        program_code: null,
                        type: null,
                    },
                    sort: 'name',
                    page: 1,
                    limit: 20
                }
            },
            form: {}
        }
    },
    methods: {
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {
                student_number: null,
                national_id: null,
                name: null,
                surname: null,
                payment_status: null,
                registration_status: null,
                faculty_code: null,
                program_code: null,
                type: null,
            };
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;
            this.datatable.rows = this.fakeData
            this.datatable.isLoading = false
        },
        selectedRows(value) {
            value.selectedRows != 0 ? this.showButton = false : this.showButton = true
        },
        openModal() {
            this.formProcess = 'show'
            this.$refs.showModal.$refs.commonModal.show()
        }
    },
};
</script>

